<template>
  <div>
    <el-row>
      <el-col :span="6" class="treeBox" v-loading="loading">
        <el-input
          prefix-icon="el-icon-search"
          placeholder="输入关键字进行过滤"
          v-model="filterText"
        ></el-input>
        <el-tree
          class="filter-tree"
          :data="treeList"
          default-expand-all
          :props="defaultProps"
          highlight-current
          :filter-node-method="filterNode"
          @node-click="handleNodeClick"
          ref="tree"
          accordion
        ></el-tree>
      </el-col>

      <el-col :span="18">
        <el-form
          :model="urlTree"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
        >
          <el-form-item>
            <el-input v-model="urlTree.name" disabled class="showInput">
              <template slot="prepend">名称</template>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-input v-model="urlTree.url" disabled class="showInput">
              <template slot="prepend">url&nbsp;&nbsp;</template>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-link type="primary" :underline="false"
              >是否为node节点&nbsp;&nbsp;</el-link
            >
            <el-switch
              v-show="urlTree.node == 0"
              v-model="urlTree.node"
              disabled
              active-color="#13ce66"
              inactive-color="#ff4949"
              :inactive-value="urlTree.node"
            ></el-switch>
            <el-switch
              v-show="urlTree.node == 1"
              v-model="urlTree.node"
              disabled
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="urlTree.node"
            ></el-switch>
            <el-switch
              v-show="urlTree.node == 2"
              v-model="urlTree.node"
              disabled
            ></el-switch>
          </el-form-item>

          <el-form-item>
            <el-button
              v-throttle
              type="success"
              size="medium"
              icon="el-icon-edit"
              @click="edit"
              >编辑</el-button
            >
            <el-button
              v-throttle
              type="danger"
              size="medium"
              icon="el-icon-delete"
              @click="remove"
              >删除</el-button
            >
            <el-button
              v-throttle
              type="primary"
              size="medium"
              icon="el-icon-plus"
              @click="addChildrenUrl"
              id="addChildren"
              >添加子路由</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <el-row>
      <el-dialog
        v-if="dialogVisible"
        :title="title"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <el-form
          :model="formUrl"
          :rules="rules"
          ref="formUrl"
          label-width="80px"
        >
          <el-form-item label="名称" prop="name">
            <el-input
              v-model="formUrl.name"
              autocomplete="off"
              class="inputWidth"
              clearable
              placeholder="请输入名称"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item label="url" prop="url">
            <el-input
              type="textarea"
              v-model="formUrl.url"
              autocomplete="off"
              class="inputWidth"
              clearable
              placeholder="请输入url"
              maxlength="100"
              show-word-limit
              :rows="4"
            ></el-input>
          </el-form-item>

          <el-form-item label prop="node">
            <el-link type="primary" :underline="false"
              >是否为node节点&nbsp;&nbsp;</el-link
            >
            <el-switch
              v-model="formUrl.node"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            v-throttle
            type="primary"
            @click="save('formUrl')"
            :loading="confirmLoading"
            >确认</el-button
          >
          <el-button v-throttle @click="cancel('formUrl')">取 消</el-button>
        </div>
      </el-dialog>
    </el-row>
  </div>
</template>
<script>
import {
  getUrlListApi,
  getUrlApi,
  addUrlApi,
  updateUrlApi,
  removeUrlApi,
} from '@/api/user/roleUrlManage.js'
import { isEmpty } from '@/utils/util.js'
export default {
  name: 'roleUrlManage',
  components: {},
  data() {
    return {
      loading: false,
      urlTree: {
        name: '',
        url: '',
        node: '',
        parentId: '',
      },
      selectedNode: {},
      filterText: '',
      treeList: [],
      defaultProps: {
        children: 'childrenAuthUrl',
        label: 'name',
      },
      title: '',
      urlText: '',
      dialogVisible: false,
      formUrl: {
        name: '',
        url: '',
        node: 0,
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        url: [{ required: true, message: '请输入url', trigger: 'blur' }],
        node: [{ required: true, message: '请输入node', trigger: 'blur' }],
      },
      confirmLoading: false,
      submitForm: {},
    }
  },
  mounted() {
    this.init()
    this.urlTree.node = 2
  },
  methods: {
    init() {
      this.initTree()
    },
    initTree() {
      this.loading = true
      const sysAuthUrlId = 0
      getUrlListApi(sysAuthUrlId)
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.treeList = res.data
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate)
    },
    //点击node时触发
    handleNodeClick(data) {
      this.urlTree = {}
      this.urlTree = data
      this.selectedNode = {}
      this.selectedNode = data
    },
    edit() {
      if (isEmpty(this.selectedNode.sysAuthUrlId)) {
        this.msgWarning('请先选择路由')
        return
      }
      this.urlText = 'edit'
      this.title = '编辑路由'
      this.dialogVisible = true
      getUrlApi(this.selectedNode.sysAuthUrlId)
        .then((res) => {
          if (res.success) {
            this.submitForm = res.data
            this.$set(this.formUrl, 'name', this.submitForm.name)
            this.$set(this.formUrl, 'url', this.submitForm.url)
            this.$set(this.formUrl, 'node', this.submitForm.node)
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {})
    },
    remove() {
      if (isEmpty(this.selectedNode.sysAuthUrlId)) {
        this.msgWarning('请先选择路由')
        return
      }
      this.title = '删除路由'
      this.globalMessageBox(
        '确实删除路由(包括所在下级)吗?',
        '提示',
        '确定',
        '取消',
        'warning'
      )
        .then(() => {
          let loading = this.globalLoading('正在删除...')
          const idList = []
          idList.push(this.selectedNode.sysAuthUrlId)
          removeUrlApi(idList)
            .then((res) => {
              loading.close()
              if (res.success) {
                this.msgSuccess('删除成功')
                this.initTree()
              } else {
                this.msgError('删除失败')
              }
            })
            .catch(() => {
              loading.close()
            })
        })
        .catch(() => {})
    },
    addChildrenUrl() {
      if (isEmpty(this.urlTree.parentId)) {
        this.msgWarning('请先选择路由')
        return
      }
      this.urlText = 'add'
      this.title = '添加子路由'
      this.dialogVisible = true
      this.formUrl = {}
      this.formUrl.parentId = this.urlTree.sysAuthUrlId
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.urlText == 'add') {
            let loading = this.globalLoading('正在添加...')
            addUrlApi(this.formUrl)
              .then((res) => {
                loading.close()
                this.dialogVisible = false
                if (res.success) {
                  this.msgSuccess('添加成功')
                  this.initTree()
                } else {
                  this.msgError('添加失败')
                }
              })
              .catch(() => {
                loading.close()
              })
          } else if (this.urlText == 'edit') {
            let loading = this.globalLoading('正在修改...')
            this.$set(this.submitForm, 'name', this.formUrl.name)
            this.$set(this.submitForm, 'url', this.formUrl.url)
            this.$set(this.submitForm, 'node', this.formUrl.node)
            updateUrlApi(this.submitForm)
              .then((res) => {
                loading.close()
                this.dialogVisible = false
                if (res.success) {
                  this.msgSuccess('修改成功')
                  this.initTree()
                  this.$set(this.selectedNode, 'name', this.submitForm.name)
                  this.$set(this.selectedNode, 'url', this.submitForm.url)
                  this.$set(this.selectedNode, 'node', this.submitForm.node)
                } else {
                  this.msgError('修改失败')
                }
              })
              .catch(() => {
                loading.close()
              })
          }
        } else {
          return false
        }
      })
    },
    handleClose() {
      this.formUrl = {}
      this.dialogVisible = false
    },
    cancel() {
      this.formUrl = {}
      this.dialogVisible = false
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
}
</script>
<style>
.treeBox {
  height: calc(100vh - 108px);
  overflow: hidden;
  box-sizing: border-box;
  padding-right: 20px;
  border-right: 1px solid #ccc;
}

.showInput {
  width: 300px;
}

#addChildren {
  /* margin-left: 69px; */
}
</style>
